import React, { Component } from 'react';
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Navigation from "../components/Navigation";
import Container from "../components/Container";
import Sidebar from "../components/Sidebar";
import styled from "styled-components";

const Main = styled.div`
  h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 26px;
    margin-top: 0px;
  }
h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 22px;
  }
h3 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 18px;
  }
p {
    margin-bottom: 8px;
  }
`

const StyledContainer = styled(Container)`
    display: flex;
    position: relative;
    
    @media (max-width: ${props => props.theme.screen.md}) {
    }
`

class Template extends Component {
  render() {
    const { markdownRemark: page } = this.props.data;
    return (
      <Layout>
        <SEO title={`Ergo Wallet | ${page.frontmatter.title}`} />
        <Navigation />
        <StyledContainer>
          <Sidebar />
          <Main>
            <h1>{page.frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: page.html }} />
          </Main>
        </StyledContainer>
      </Layout>
    );
  }
}

export default Template
export const pageQuery = graphql`
  query DocsByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`
  ;
